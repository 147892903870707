export const userAuthStepFormOne = {
  // 表单数据流
  name: {
    label: '身份证姓名',
    autosize: false,
    type: 'text',
    rules: [
      { required: true, message: '必填信息不能为空' },
      { pattern: /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{1,20}$/, message: '非合法名称' }
    ]
  },
  idCard: {
    label: '身份证号码',
    type: 'text',
    autosize: false,
    rules: [
      { required: true, message: '必填信息不能为空' },
      {
        pattern: /^(1[1-5]|2[1-3]|3[1-7]|4[1-6]|5[0-4]|6[1-5]|7[1]|8[1-2]|9[1])\d{4}(18|19|20)\d{2}[01]\d[0123]\d{4}[\dxX]$/,
        message: '身份证信息不合法'
      }
    ]
  },
  address: {
    label: '身份证地址',
    autosize: true,
    type: 'textarea',
    rules: [{ required: true, message: '必填信息不能为空' }]
  },
  idCardIssueOrganization: {
    label: '签发机关',
    autosize: true,
    type: 'textarea',
    rules: [
      { required: true, message: '必填信息不能为空' },
      {
        validator: value => {
          if (value && !/^\s*[\u4e00-\u9fa5]{5,}\s*$/.test(value)) return false;
          return true;
        },
        message: '不少于5位中文字符'
      }
    ]
  }
};
