<template>
  <div class="autonym-step1-content">
    <div class="pl-24">
      <label class="ml-10 require text-18 text-main font-500">身份证照片 </label>
    </div>
    <div class="px-24 py-8 id-card-wrap d-flex justify-space-between">
      <tos-uploader
        class="id-card-front id-card"
        @afterUploaded="afterUploadedCardFront"
        v-model="cardFrontFileList"
        :max-count="1"
        >点击上传人像面</tos-uploader
      >
      <tos-uploader
        class="id-card-back id-card"
        @afterUploaded="afterUploadedCardBack"
        v-model="cardBackFileList"
        :max-count="1"
        >点击上传国徽面</tos-uploader
      >
    </div>
    <van-form class="px-12" ref="form">
      <van-field
        v-for="(item, key) in FormObj"
        :key="key"
        required
        v-model="userVertifiedRef[key]"
        input-align="right"
        :name="item.name"
        :label="item.label"
        :placeholder="!disabled ? '请输入' : '识别中...'"
        :disabled="disabled"
        :type="item.type"
        :autosize="item.autosize"
        :rules="item.rules"
        rows="1"
      />

      <TimeMachine v-model="timeDateRef" label="身份证有效期" :yearSelects="[5, 10, 20]" />

      <div class="mt-24" style="height:0.72rem;">
        <div class="btn-box">
          <van-button size="large" @click="handleToNext" type="primary">下一步</van-button>
        </div>
      </div>
    </van-form>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, computed, onMounted } from '@vue/composition-api';
import TosUploader from '@/components/uploader/uploader.vue';
import TimeMachine from '@/components/TimeMachine';
import { useUserVerifiedStore } from '@/store/verified';
import { apiOcrIdCard } from '@/api/ocr';
import { userAuthStepFormOne } from '../constants';
import { Toast } from 'vant';
import { isValidEndDate } from '@/views/driver/driververfied/date-validate';
import dayjs from 'dayjs';

export default defineComponent({
  components: {
    TosUploader,
    TimeMachine,
    Toast
  },
  setup(_, ctx) {
    const userVerifiedStore = useUserVerifiedStore();
    const FormObj = reactive(userAuthStepFormOne);
    const disabled = ref(false);

    const cardFrontFileList = ref([]);
    const cardBackFileList = ref([]);
    // 优先从缓存中取数据
    const timeDateRef = reactive({
      startDate: userVerifiedStore.verifiedInfo.idCardStartDate
        ? new Date(userVerifiedStore.verifiedInfo.idCardStartDate)
        : null,
      endDate: userVerifiedStore.verifiedInfo.idCardEndDate
        ? new Date(userVerifiedStore.verifiedInfo.idCardEndDate)
        : null
    });
    const userVertifiedRef = computed(() => userVerifiedStore.verifiedInfo);

    onMounted(() => {
      // 如果缓存中有图片需要进行回填
      if (userVerifiedStore?.verifiedInfo?.idCardFrontUrl) {
        cardFrontFileList.value = [
          {
            fileName: userVerifiedStore?.verifiedInfo?.idCardFrontUrl || '',
            status: 'uploading',
            message: '加载中'
          }
        ];
      }
      if (userVerifiedStore?.verifiedInfo?.idCardBackUrl) {
        cardBackFileList.value = [
          {
            fileName: userVerifiedStore?.verifiedInfo?.idCardBackUrl || '',
            status: 'uploading',
            message: '加载中'
          }
        ];
      }
    });

    const handleToNext = async () => {
      await ctx.refs.form?.validate();
      if (
        !isValidEndDate(timeDateRef.startDate, timeDateRef.endDate) ||
        !timeDateRef.startDate ||
        !timeDateRef.endDate
      ) {
        Toast.fail('身份证有效期不符合规则');
        return;
      }

      // 去下一步对数据进行缓存 需要同步到storage中
      if (!cardFrontFileList?.value[0]?.fileName || !cardBackFileList?.value[0]?.fileName) {
        Toast.fail('图片信息不能为空!');
      } else {
        userVerifiedStore.updateInfo({
          ...userVertifiedRef.value,
          idCardStartDate: dayjs(timeDateRef.startDate).valueOf(),
          idCardEndDate: dayjs(timeDateRef.endDate).valueOf(),
          idCardFrontUrl: cardFrontFileList?.value[0]?.fileName,
          idCardBackUrl: cardBackFileList?.value[0]?.fileName,
          step: 1
        });
        userVerifiedStore.saveInfo();
      }
    };

    async function afterUploadedCardFront(file) {
      file.status = 'uploading';
      file.message = '识别中';
      disabled.value = true;
      try {
        const res = await apiOcrIdCard({ frontImageUrl: file.url });
        const { data } = res;
        const { cardNumber, address, name, gender, nation } = data;
        userVerifiedStore.updateInfo({
          idCard: cardNumber,
          address,
          name,
          gender: gender === '男' ? 1 : 2,
          nation
        });
      } finally {
        disabled.value = false;
        file.status = 'done';
      }
    }

    async function afterUploadedCardBack(file) {
      file.status = 'uploading';
      file.message = '识别中';
      disabled.value = true;
      try {
        const res = await apiOcrIdCard({ backImageUrl: file.url });
        const { data } = res;
        const { authority, effectiveDate, expirationDate } = data;
        timeDateRef.startDate = new Date(effectiveDate);
        timeDateRef.endDate = expirationDate === '长期' ? new Date('2199/12/31') : new Date(expirationDate);
        userVerifiedStore.updateInfo({
          idCardIssueOrganization: authority
        });
      } catch (error) {
        console.error(error);
      } finally {
        disabled.value = false;
        file.status = 'done';
      }
    }

    return {
      FormObj,
      timeDateRef,
      cardFrontFileList,
      cardBackFileList,
      afterUploadedCardFront,
      afterUploadedCardBack,
      handleToNext,
      userVertifiedRef,
      disabled
    };
  }
});
</script>

<style lang="less">
.autonym-step1-content {
  .id-card-wrap {
    .id-card {
      width: 1.6rem;
      height: 1.26rem;
    }

    .id-card-front {
      .tos-uploader-bg {
        background: url('https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_xiaochegnxu/zhengjiantuli/shenfenzhengrenxiangmian@3x.png')
          no-repeat;
        background-size: 100% 100%;
      }
    }

    .id-card-back {
      .tos-uploader-bg {
        background: url('https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_xiaochegnxu/zhengjiantuli/shenfenzhengguohuiye@3x.png')
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>
