<template>
  <div>
    <div class="bg-white">
      <div class="van-cell van-cell--required d-flex justify-space-between">
        <div class="van-cell__title van-field__label">
          <span>{{ label }}起始时间</span>
        </div>
        <div
          :class="!timeDateRef.endDate ? 'text_note' : 'text_main'"
          @click="
            visible = true;
            timeDateType = 0;
          "
        >
          {{ timeDateRef.startDate ? formatToDateTime(timeDateRef.startDate, 'yyyy-MM-dd') : '请选择起始时间' }}
        </div>
      </div>
      <div class=" d-flex justify-space-between"></div>
    </div>
    <div class="bg-white">
      <div class="van-cell van-cell--required d-flex justify-space-between">
        <div class="van-cell__title van-field__label">
          <span :style="{ color: errorTitle ? 'red' : '' }">{{ label }}结束时间</span>
          <div v-if="errorTitle" class="font-12" style="color: red">
            不正确
          </div>
        </div>
        <div
          :class="!timeDateRef.endDate ? 'text_note' : 'text_main'"
          @click="
            visible = true;
            timeDateType = 1;
          "
        >
          {{ timeDateRef.endDate ? formatToDateTime(timeDateRef.endDate, 'yyyy-MM-dd') : '请选择结束时间' }}
        </div>
      </div>
      <div class=" d-flex justify-space-between"></div>
    </div>
    <!-- 起始日期 -->
    <Popup v-model="visible" position="bottom" round :close-on-popstate="true">
      <DatetimePicker
        v-show="!timeDateType"
        v-model="timeDateRef.startDate"
        type="date"
        :visible-item-count="6"
        :min-date="minDate"
        @cancel="visible = false"
        @confirm="visible = false"
      />
      <DatetimePicker
        v-show="timeDateType"
        v-model="timeDateRef.endDate"
        type="date"
        :min-date="minDate"
        :maxDate="maxDate"
        :visible-item-count="6"
        @cancel="visible = false"
        @confirm="visible = false"
      />
      <div class="d-flex justify-space-between btns-group px-16" v-show="timeDateType">
        <Tag
          v-for="(item, index) in yearSelects"
          :key="index"
          type="primary"
          size="large"
          @click="handleChangeEndDate(item)"
          >{{ item }}年</Tag
        >
        <Tag type="primary" size="large" @click="handleChangeEndDate()">长期</Tag>
      </div>
    </Popup>
  </div>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api';
import { formatToDateTime } from '@wlhy-web-lib/shared';
import { DatetimePicker, Tag, Popup } from 'vant';
export default defineComponent({
  components: {
    DatetimePicker,
    Tag,
    Popup
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        startDate: new Date(),
        endDate: new Date()
      })
    },
    errorTitle: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: '身份证'
    },
    yearSelects: {
      type: Array,
      default: [5, 10, 20]
    }
  },
  setup(props, ctx) {
    const maxDate = ref(new Date('2199/12/31'));
    const minDate = ref(new Date('1970/01/02'));
    const timeDateRef = ref(props.value);
    const visible = ref(false);
    const timeDateType = ref(0);
    const handleChangeEndDate = years => {
      // 直接选择时长年限
      let year = timeDateRef.value.startDate.getFullYear();
      let month = timeDateRef.value.startDate.getMonth();
      let day = timeDateRef.value.startDate.getDate();
      if (years) {
        if (month === 1 && day === 29) {
          day = 28;
        }
        timeDateRef.value.endDate = new Date(`${+year + +years}/${+month + 1}/${day} 08:00:00`);
      } else {
        timeDateRef.value.endDate = new Date(`2199/12/31`);
      }
      ctx.emit('input', { ...timeDateRef.value });
      visible.value = false;
    };
    return {
      visible,
      minDate,
      maxDate,
      timeDateRef,
      timeDateType,
      formatToDateTime,
      handleChangeEndDate
    };
  }
});
</script>
<style lang="less" scoped>
.van-tag--primary {
  background-color: #f1f3f5 !important;
  color: #444 !important;
}
.btns-group {
  padding: 0.16rem 0;
  height: 0.7rem;
  border-top: 0.5px solid #f5f5f5;
}
</style>
